import gsap from 'gsap'

export const useGsap = ({ onStart, onComplete } = {}) => {
  let tl
  const showtime = async () => {
    onStart?.({ isReverse: false })
    createTl()
    tl.to('.quick-expand-cart', {
      bottom: `-${5 / 37.5}rem`,
      right: !gbCommonInfo.GB_cssRight ? `-${5 / 37.5}rem` : null,
      left: gbCommonInfo.GB_cssRight ? `-${5 / 37.5}rem` : null,
      width: `${46 / 37.5}rem`,
      height: `${46 / 37.5}rem`,
      duration: 0.15,
    })
    tl.to('.quick-expand-cart__core', {
      width: `${46 / 37.5}rem`,
      height: `${46 / 37.5}rem`,
      duration: 0.12,
    }, '<')
    tl.to('.quick-expand-cart', {
      width: `calc(8.98rem + 10px)`,
      duration: 0.38,
    })
  }

  const endtime = () => {
    onStart?.({ isReverse: true })
    tl?.reverse()
  }

  const createTl = () => {
    if (tl) return
    tl = gsap.timeline({
      onComplete: () => {
        onComplete?.()
      },
      onReverseComplete: () => {
        killTl()
      },
    })
  }
  const killTl = () => {
    if (!tl) return
    tl.seek(0).kill()
    tl = null
  }

  return {
    showtime,
    endtime,
    killTl,
  }
}
