import { createApp } from 'vue'
import { createStore } from 'public/src/pages/product_app/store/modules/cart_v2/index.js'
import { fetchCartAbtAndLanguage } from 'public/src/pages/cart_v2/components/business/services.js'

let instance
if(typeof window !== 'undefined') {
  requestIdleCallback(() => {
    window.preFetchMiniCartABTLanguage = fetchCartAbtAndLanguage()
  })
}

export default {
  open(config) {
    const isServer = typeof window === 'undefined'
    if (isServer) return

    if (instance) {
      return instance.open(config)
    }

    return new Promise((resolve) => {
      let store = createStore('mini_cart')
      store.commit('mini_cart/updateState', { key: 'moduleName', value: 'mini_cart' })
      import(/* webpackChunkName: "mini-cart" */ './MiniCart.vue').then((components) => {
        const appDom = document.createElement('div')
        const app = createApp(components.default)
        if(window._gb_app_?.$router){
          app.use(window._gb_app_.$router)
        }
        app.use(store)
        const vm = app.mount(appDom)
        document.body.appendChild(vm.$el)
        const comp = vm
        comp.open(config)
        instance = comp
        window.miniCart = instance

        resolve(instance)
      })
    })
  },
  close() {
    if (!instance) return
    instance.handleClose()
  },
}
