import { ref } from 'vue'

const animationTime = 0.5 // 拉通态打开时间

// strongDisplayDuration 秒内不能关闭拉通态
const useLock = (config, callback) => {
  let lockTimer = null
  let lock = ref(false)
  const triggerLock = () => {
    clearLockTimer()
    lock.value = true
    lockTimer = setTimeout(() => {
      clearLockTimer()
      lock.value = false
      callback?.()
    }, (config.value.strongDisplayDuration + animationTime) * 1000)
  }
  const clearLockTimer = () => {
    clearTimeout(lockTimer)
    lockTimer = null
  }
  return {
    lock,
    triggerLock,
    clearLockTimer,
  }
}

// inactiveTimeout 秒无操作则关闭拉通态
const useInactionTimer = (config, callback) => {
  let inactionTimer = null
  const playInactionTimer = () => {
    // console.log(`%c 未交互计时开始 ${new Date()}`, 'color: #fff; background: red; font-size: 12px; font-weight: bold; padding: 4px 8px; border-radius: 4px;')
    clearInactionTimer()
    inactionTimer = setTimeout(() => {
      clearInactionTimer()
      callback?.()
    }, config.value.inactiveTimeout * 1000)
  }
  const clearInactionTimer = () => {
    clearTimeout(inactionTimer)
    inactionTimer = null
  }
  return {
    playInactionTimer,
    clearInactionTimer,
  }
}

// triggerTimers 次无操作则不再打开拉通态
const useNegative = (scene, config) => {
  const cacheKey = `quick_expand_cart_negative_count_${scene}`
  const getIsNegative = () => getNegative() >= config.value.triggerTimers
  const setNegative = () => {
    sessionStorage.setItem(cacheKey, getNegative() + 1)
  }
  const getNegative = () => {
    return +(sessionStorage.getItem(cacheKey) || 0)
  }
  const removeNegative = () => {
    sessionStorage.removeItem(cacheKey)
  }
  return {
    getIsNegative,
    setNegative,
    removeNegative,
  }
}

// strongNoDisplayDuration 秒内不能打开拉通态
const strongNoDisplayDuration = 60
const useCanDisplay = (scene) => {
  const setDisplayTime = () => {
    sessionStorage.setItem(`quick_expand_cart_display_time_${scene}`, Date.now())
  }
  const getDisplayTime = () => {
    return +(sessionStorage.getItem(`quick_expand_cart_display_time_${scene}`) || 0)
  }
  const getIsCanDisplay = () => {
    return Date.now() - getDisplayTime() > strongNoDisplayDuration * 1000
  }
  return {
    setDisplayTime,
    getIsCanDisplay,
  }
}

export const useFatigue = (scene, isCustomTrigger, config, callbacks) => {
  let playInactionTimerBeforeLock = false
  let triggerEndtimeBeforeLock = false
  let actionFlag = false // 展开期间是否操作过

  const { getIsCanDisplay, setDisplayTime } = useCanDisplay(scene)
  const { getIsNegative, setNegative, removeNegative } = useNegative(scene, config)
  const { lock, triggerLock, clearLockTimer } = useLock(config, () => {
    // 强显期间通知关闭拉通态, 则强显结束后立即关闭拉通态
    // console.log(`%c ${config.value.strongDisplayDuration}秒强显结束 ${new Date()}`, 'color: #fff; background: red; font-size: 12px; font-weight: bold; padding: 4px 8px; border-radius: 4px;')
    if (triggerEndtimeBeforeLock) {
      clearInactionTimer()
      triggerEndtime()
    } else {
      if (!actionFlag || playInactionTimerBeforeLock) _playInactionTimer()
    }
  })
  const { playInactionTimer, clearInactionTimer } = useInactionTimer(config, () => {
    // console.log(`%c 用户${config.value.inactiveTimeout}秒未交互 ${new Date()}`, 'color: #fff; background: red; font-size: 12px; font-weight: bold; padding: 4px 8px; border-radius: 4px;')
    triggerEndtime()
  })

  const _playInactionTimer = () => {
    if (isCustomTrigger) return
    if (lock.value) {
      playInactionTimerBeforeLock = true
      return
    }
    playInactionTimer()
  }

  const triggerAction = () => {
    // console.log(`%c 用户有交互`, 'color: #fff; background: red; font-size: 12px; font-weight: bold; padding: 4px 8px; border-radius: 4px;')
    clearInactionTimer()
    actionFlag = true
    removeNegative()
  }

  const triggerShowtime = () => {
    if (getIsNegative()) {
      // console.log(`%c 失败: ${config.value.triggerTimers}次无操作`, 'color: #fff; background: red; font-size: 12px; font-weight: bold; padding: 4px 8px; border-radius: 4px;')
      return false
    }
    if (!getIsCanDisplay()) {
      // console.log(`%c 失败: ${strongNoDisplayDuration}秒内不能打开拉通态`, 'color: #fff; background: red; font-size: 12px; font-weight: bold; padding: 4px 8px; border-radius: 4px;')
      return false
    }

    playInactionTimerBeforeLock = false
    triggerEndtimeBeforeLock = false
    actionFlag = false

    triggerLock()

    callbacks.showtime()

    setDisplayTime()

    // console.log(`%c 成功 ${new Date()}`, 'color: #fff; background: red; font-size: 12px; font-weight: bold; padding: 4px 8px; border-radius: 4px;')
    return true
  }

  const triggerEndtime = (isForce = false) => {
    // console.log(`%c 关闭拉通态 ${new Date()}`, 'color: #fff; background: red; font-size: 12px; font-weight: bold; padding: 4px 8px; border-radius: 4px;')
    if (!isForce && lock.value) {
      triggerEndtimeBeforeLock = true
      return
    }

    clearLockTimer()
    clearInactionTimer()

    if (!actionFlag) setNegative()

    callbacks.endtime()
  }

  return {
    triggerAction,
    playInactionTimer: _playInactionTimer,
    triggerShowtime,
    triggerEndtime,
  }
}
