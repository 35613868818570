<template>
  <div
    v-if="label"
    class=""
    :class="[
      'quick-cart-tag',
      `quick-cart-tag_${label.type}`,
    ]"
  >
    <!-- 免邮 -->
    <template v-if="label.type === 'freeshipping'">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="11"
        height="10"
        viewBox="0 0 11 10"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.70418 1.95992V0.5H0.505127V2.82253V5.64669V7.72576H1.38061C1.61999 6.88755 2.39173 6.27393 3.30679 6.27393C4.22185 6.27393 4.99359 6.88755 5.23297 7.72576H5.80847C6.04786 6.88755 6.81959 6.27393 7.73465 6.27393C8.6497 6.27393 9.42144 6.88754 9.66083 7.72574H10.2849V2.8036L7.70418 1.95992ZM7.70418 4.72558V3.01201L9.28455 3.52858V4.72558H7.70418Z"
          fill="white"
        />
        <circle
          cx="7.7583"
          cy="8.27686"
          r="1"
          fill="white"
        />
        <circle
          cx="3.30688"
          cy="8.27686"
          r="1"
          fill="white"
        />
      </svg>
      <span class="quick-cart-tag__text">Free</span>
    </template>
    <!-- 优惠金额(比较特殊) -->
    <template v-else-if="label.type === 'save'">
      <template v-if="label.text.length > 7">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
        >
          <path
            d="M1.08385 3.82435L3.89191 6.6324L6.1298 4.39451L10.1931 8.45767"
            stroke="white"
            stroke-width="1.5"
          />
          <path
            d="M10.5219 4.59961L10.5219 9.00049L6.12103 9.00049"
            stroke="white"
            stroke-width="1.5"
          />
        </svg>
        <span class="quick-cart-tag__text">SAVE</span>
      </template>
      <template v-else>
        <span class="quick-cart-tag__save-text">{{ label.text }}</span>
      </template>
    </template>
    <!-- 赠品 -->
    <template v-else-if="label.type === 'gift'">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="13"
        viewBox="0 0 12 13"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.16068 3.33313L8.33708 2.25969L7.66304 1.521L6.00506 3.03387L4.39965 1.52585L3.715 2.25472L4.86306 3.33313H1.16675L1.16681 6.49976H1.83341V10.9998H10.1667V6.49976H10.8335L10.8334 3.33313H7.16068ZM9.16675 6.49976H2.83341V9.99976H9.16675V6.49976ZM9.83348 5.49976L9.83342 4.33313H2.16675L2.16681 5.49976H9.83348Z"
          fill="white"
        />
      </svg>
      <span class="quick-cart-tag__text">GIFT</span>
    </template>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    label: {
      type: Object,
      default: null,
    },
  },
})
</script>

<style lang="less">
/* 利诱点样式 */
@colorFree: #1B8157; // 免邮
@colorSave: #FA6338; // 优惠金额
@colorGift: #FA6338;// 赠品

// 利诱标签通用样式
.quick-cart-tag {
  box-sizing: content-box;
  position: absolute;
  z-index: @zindex-hack;
  bottom: -6px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.12rem;
  height: 14px;
  font-size: 9px;
  color: white;
  border-radius: 8px;
}
.quick-cart-tag_gift {
  background-color: @colorGift;
}
.quick-cart-tag_freeshipping {
  background-color: @colorFree;
}
.quick-cart-tag_save {
  background-color: @colorSave;
  height: 16px;
  > .quick-cart-tag__save-text {
    transform: scale(.8);
    font-size: 12px;
    line-height: 16px;
    white-space: nowrap;
  }
}
.quick-cart-tag__text {
  margin-left: 1px;
  transform: scale(.9);
}
</style>
