<template>
  <svg
    preserveAspectRatio="xMinYMin meet"
    viewBox="0 0 140 140"
    class="quick-cart-circle"
  >
    <g>
      <circle
        r="64.5"
        cx="50%"
        cy="50%"
        :stroke="stroke || '#231f20'"
        stroke-width="8"
        fill-opacity="1"
        fill="white"
        :class="[
          'quick-cart-circle__default',
          animateCartBagStroke && 'quick-cart-circle__activated',
          quickExpandCartIsOpen && 'quick-cart-circle_expand'
        ]"
      />
    </g>
  </svg>
</template>

<script>
import { defineComponent } from 'vue'
//  0到1 加载圈SVG
export default defineComponent({
  props: {
    stroke: {
      type: [String, Boolean],
      default: ''
    },
    animateCartBagStroke: {
      type: Boolean,
      default: false
    },
    quickExpandCartIsOpen: {
      type: Boolean,
      default: false,
    },
  }
})
</script>

<style lang="less">
@keyframes quick-cart-stroke {
  to {
    stroke-dashoffset: 0;
  }
}
.quick-cart-circle {
  position: absolute;
  left: 0;
  top: 0;
  transform: rotate(-90deg);
  width: 100%;
  height: 100%;

  &_expand {
    stroke-dashoffset: 1000 !important;
  }
}
.quick-cart-circle__default {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
}
.quick-cart-circle__activated {
  animation: .3s cubic-bezier(0.4, 0.0, 1, 1) .1s forwards quick-cart-stroke;
}
</style>
