<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="18"
      height="18"
      fill="white"
      fill-opacity="0.01"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.0564 5.18968V3H2.25781V6.4838V10.72V13.8386H3.57053C3.92936 12.5809 5.08715 11.6602 6.46001 11.6602C7.83286 11.6602 8.99065 12.5809 9.34949 13.8386H10.2125C10.5713 12.5809 11.7291 11.6602 13.102 11.6602C14.4747 11.6602 15.6324 12.5808 15.9914 13.8383H16.9271V6.45511L13.0564 5.18968ZM13.0564 9.33808V6.76778L15.4268 7.54258V9.33808H13.0564Z"
      fill="white"
    />
    <circle
      cx="13.1375"
      cy="14.666"
      r="1.5"
      fill="white"
    />
    <circle
      cx="6.46045"
      cy="14.666"
      r="1.5"
      fill="white"
    />
  </svg>
</template>
