<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.7409 4.25046L12.5055 2.64027L11.4944 1.53223L9.00739 3.80161L6.59929 1.5396L5.57231 2.6329L7.29434 4.25046H1.75V8.75046H2.75V16.2505H15.25V8.75046H16.25V4.25046H10.7409ZM13.75 8.75046H4.25V14.7505H13.75V8.75046ZM14.75 7.25046V5.75046H3.25V7.25046H14.75Z"
      fill="white"
    />
  </svg>
</template>
