<template>
  <!-- 气泡提示 -->
  <!-- 不同气泡提示不同 -->
  <div
    :class="[
      'quick-cart-tip',
      `quick-cart-tip_${status}`,
    ]"
    @click="onClick"
  >
    <template v-if="status === 'freeshipping'">
      <free-tip />
    </template>
    <template v-if="status === 'gift'">
      <gift-tip />
    </template>
    <!-- 处理(针对劵类型的免邮，返回的是富文本，所以兼容这种情况) -->
    <p v-html="htmlText"></p>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import freeTip from 'public/src/pages/product_list_v2/components/CartBag/freeTip.vue'
import giftTip from 'public/src/pages/product_list_v2/components/CartBag/giftTip.vue'

export default defineComponent({
  components: {
    freeTip,
    giftTip
  },
  emits: ['click'],
  props: {
    status: {
      type: String,
      default: ''
    },
    htmlText: {
      type: String,
      default: ''
    },
  },
  methods: {
    onClick() {
      this.$emit('click')
    }
  },
})
</script>

<style lang="less">
/* 利诱点样式 */
@colorFree: #1B8157; // 免邮
@colorSave: #FA6338; // 优惠金额
@colorGift: #FA6338; // 赠品

.quick-cart-tip {
  position: fixed;
  right: 0;
  bottom: 0;
  // z-index: @zindex-list-wrapper;
  display: flex;
  align-items: center;
  gap: 3px;
  padding-left: 0.213rem;
  padding-right: 0.96rem;
  width: 0;
  max-width: 8.98rem;
  height: 0.96rem;
  font-size: 12px;
  border-radius: 0.96rem;
  transform: translate3d(0, 0, 0);
  visibility: hidden;
  overflow: hidden;
  opacity: 1;
  > svg {
    flex-shrink: 0;
  }
  > p {
    width: max-content;
    line-height: normal;
    font-size: 12px;
    font-weight: 400;
    color: #FFF;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
}
.quick-cart-tip_freeshipping {
  background-color: @colorFree;
}
.quick-cart-tip_save {
  background-color: @colorSave;
}
.quick-cart-tip_gift {
  background-color: @colorGift;
}

</style>
