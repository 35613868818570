import { ref, computed, nextTick } from 'vue'

export const usePoint = (scene, data) => {
  const cacheKey = `quick_expand_cart_point_${scene}`
  const saveLurePoint = computed(() => data.value.saveLurePointList?.[0])

  let promotionLurePoint
  const pointTransitionName = ref('')
  const pointType = ref('') // 'save' | 'promotion'
  const currentShowPoint = computed(() => {
    if (pointType.value === 'save') return saveLurePoint.value
    if (pointType.value === 'promotion') return promotionLurePoint
    return null
  })

  const getActualPoint = () => {
    const actual_point = []
    if (data.value.clickBtnLabel) actual_point.push('freeshipping')
    if (!!saveLurePoint.value) actual_point.push(saveLurePoint.value.biData)
    if (!!promotionLurePoint) actual_point.push(promotionLurePoint.biData)
    return actual_point.join()
  }

  const getCachePointTypes = () => {
    const cache = sessionStorage.getItem(cacheKey)
    if (cache) {
      return cache.split(',')
    }
    return []
  }

  const setCachePointTypes = () => {
    const type = pointType.value === 'promotion' && currentShowPoint.value?.type
    if (!type) return
    const res = getCachePointTypes()
    res.push(type)
    sessionStorage.setItem(cacheKey, res.join())
  }

  const getPromotionLurePoint = (list) => {
    return (list ?? data.value.promotionLurePointList)?.find(point => !getCachePointTypes().includes(point.type))
  }

  let switchTimer = null
  const triggerSwitch = () => {
    clearSwitchTimer()
    switchTimer = setTimeout(async () => {
      clearSwitchTimer()
      pointTransitionName.value = 'quick-expand-cart__point'
      await nextTick()
      pointType.value = 'save'
    }, 3000)
  }
  const clearSwitchTimer = () => {
    clearTimeout(switchTimer)
    switchTimer = null
  }

  const handlePoint = async () => {
    pointType.value = ''
    pointTransitionName.value = ''
    await nextTick()
    promotionLurePoint = getPromotionLurePoint()
    let has = false
    if (promotionLurePoint) {
      pointType.value = 'promotion'
      has = true
    } else if (saveLurePoint.value) {
      pointType.value = 'save'
      has = true
    }
    if (promotionLurePoint && saveLurePoint.value) {
      triggerSwitch()
    }
    return has
  }

  const getHasPoint = (cartHorizontalLurePointInfo) => {
    if (!cartHorizontalLurePointInfo) return false
    const promotionLurePointList = cartHorizontalLurePointInfo.promotionLurePointList
    const hasPromotionLurePoint = promotionLurePointList?.length && getPromotionLurePoint(promotionLurePointList)
    const hasSaveLurePoint = cartHorizontalLurePointInfo.saveLurePointList?.[0]
    if (!hasSaveLurePoint && !hasPromotionLurePoint) return false
    return true
  }

  return {
    pointTransitionName,
    currentShowPoint,
    getActualPoint,
    handlePoint,
    clearSwitchTimer,
    getHasPoint,
    setCachePointTypes,
  }
}
