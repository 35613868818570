<template>
  <div
    :class="[
      'quick-expand-cart',
      isOpen && 'quick-expand-cart_open',
    ]"
    :style="rootStyle"
    @click="onClick"
  >
    <div class="quick-expand-cart__core">
      <slot></slot>
    </div>
    <div class="quick-expand-cart__wrap">
      <div
        v-if="currentShowPoint"
        class="quick-expand-cart__content"
      >
        <Transition :name="pointTransitionName">
          <div
            v-if="currentShowPoint.type === 'save'"
            key="save"
            class="quick-expand-cart__save"
          >
            <div class="quick-expand-cart__save-text">
              <p v-html="currentShowPoint.lurePointItem.bubble.clickText"></p>
            </div>
          </div>
          <div
            v-else
            key="promotion"
            class="quick-expand-cart__promotion"
          >
            <div
              v-if="currentShowPoint.lurePointItem.bubble.icon"
              class="quick-expand-cart__promotion-icon"
            >
              <img :src="currentShowPoint.lurePointItem.bubble.icon" />
            </div>
            <div
              v-else-if="currentShowPoint.lurePointItem.bubble.goods"
              class="quick-expand-cart__promotion-imgs"
            >
              <div class="quick-expand-cart__promotion-img1">
                <img
                  v-if="currentShowPoint.lurePointItem.bubble.goods[0]"
                  :src="currentShowPoint.lurePointItem.bubble.goods[0].goodsImg"
                />
              </div>
              <div class="quick-expand-cart__promotion-img2">
                <img
                  v-if="currentShowPoint.lurePointItem.bubble.goods[1]"
                  :src="currentShowPoint.lurePointItem.bubble.goods[1].goodsImg"
                />
              </div>
            </div>
            <div class="quick-expand-cart__promotion-text">
              <p v-html="currentShowPoint.lurePointItem.bubble.clickText"></p>
            </div>
          </div>
        </Transition>
      </div>
      <div class="quick-expand-cart__right">
        <div
          class="quick-expand-cart__btn"
          @click.stop="onClickBtn"
        >
          <span>{{ data.clickBtnText }}</span>
        </div>
      </div>
    </div>
    <div
      v-if="isShowBtnTag && clickBtnLabel"
      class="quick-expand-cart__btn-tag"
      @click.stop="onClickBtn"
    >
      <img
        v-if="clickBtnLabel.icon"
        :src="clickBtnLabel.icon"
      />
      <span>{{ clickBtnLabel.text }}</span>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'
import { useFatigue } from './useFatigue.js'
import { usePoint } from './usePoint.js'
import { useGsap } from './useGsap.js'

const props = defineProps({
  // 场景值
  // 泛列表 page_list_hovercart
  // 商详 item_detail_hovercart
  // 支付成功页 paid_successfully_hovercart
  scene: {
    type: String,
    default: '',
  },
  isCustomTrigger: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['click', 'click-btn', 'change'])

let callback = null
const data = ref({})
const clickBtnLabel = computed(() => data.value.clickBtnLabel)
const horizontalLurePointConfig = computed(() => ({
  strongDisplayDuration: 3, // 秒内不能关闭拉通态
  inactiveTimeout: 15, // 秒内无操作则关闭拉通态
  triggerTimers: 3, // 次无操作则不再打开拉通态
  ...data.value.horizontalLurePointConfig,
}))
const isOpen = ref(false)

const { pointTransitionName, currentShowPoint, getActualPoint, handlePoint, clearSwitchTimer, getHasPoint, setCachePointTypes } = usePoint(props.scene, data)
const { showtime, endtime } = useGsap({
  onStart: ({ isReverse } = {}) => {
    isShowBtnTag.value = false
    isOpen.value = !isReverse
    emit('change', isOpen.value)
  },
  onComplete: () => {
    callback?.()
    callback = null
    isShowBtnTag.value = true
  },
})
const { triggerAction, playInactionTimer, triggerShowtime, triggerEndtime } = useFatigue(
  props.scene,
  props.isCustomTrigger,
  horizontalLurePointConfig,
  { showtime, endtime },
)

const isShowBtnTag = ref(false)
const rootStyle = computed(() => ({
  '--btn-tag-color': clickBtnLabel.value?.textColor,
  '--btn-tag-bg-color': clickBtnLabel.value?.bgColor,
}))

const onExpose = () => {
  daEventCenter.triggerNotice({
    id: 'expose_floating_bag_expansion.comp_quick-cart',
    data: {
      actual_point: getActualPoint(),
      available_point: data.value?.lurePointBiData.bubbleAvailablePoint || '-',
    },
  })
}

const onClick = async () => {
  daEventCenter.triggerNotice({
    id: 'click_floating_bag_expansion.comp_quick-cart',
    data: {
      click_type: 'icon_point',
      actual_point: getActualPoint(),
      available_point: data.value?.lurePointBiData.bubbleAvailablePoint || '-',
    },
  })

  triggerAction()

  emit('click', { currentShowPoint: currentShowPoint.value })
}

const onClickBtn = () => {
  daEventCenter.triggerNotice({
    id: 'click_floating_bag_expansion.comp_quick-cart',
    data: {
      click_type: 'gtc',
      actual_point: getActualPoint(),
      available_point: data.value?.lurePointBiData.bubbleAvailablePoint || '-',
    },
  })

  triggerAction()

  emit('click-btn', { currentShowPoint: currentShowPoint.value })
}

const open = async (dataByBff, config) => {
  // console.log(`%c 打开拉通态 ${new Date()}`, 'color: #fff; background: red; font-size: 12px; font-weight: bold; padding: 4px 8px; border-radius: 4px;')
  if (!getHasPoint(dataByBff?.cartHorizontalLurePointInfo)) {
    // console.log(`%c 失败: 无利诱数据`, 'color: #fff; background: red; font-size: 12px; font-weight: bold; padding: 4px 8px; border-radius: 4px;')
    close(true)
    config?.callback()
    return
  }

  data.value = dataByBff.cartHorizontalLurePointInfo

  if (isOpen.value) {
    // console.log(`%c 失败: 已打开，只更新利诱数据`, 'color: #fff; background: red; font-size: 12px; font-weight: bold; padding: 4px 8px; border-radius: 4px;')
    config?.callback()
    return
  }

  if (config?.noTips) {
    // console.log(`%c 失败：无需打开，只更新利诱数据`, 'color: #fff; background: red; font-size: 12px; font-weight: bold; padding: 4px 8px; border-radius: 4px;')
    config?.callback()
    return
  }

  const res = await handlePoint()
  if (!res) {
    // console.log(`%c 失败: 无利诱数据2`, 'color: #fff; background: red; font-size: 12px; font-weight: bold; padding: 4px 8px; border-radius: 4px;')
    config?.callback()
    return
  }

  const showtimeRes = triggerShowtime()
  if (!showtimeRes) {
    config?.callback()
    return
  }

  setCachePointTypes()

  callback = config?.callback()

  onExpose()
}
const close = (isForce = false) => {
  if (!isOpen.value) return
  clearSwitchTimer()
  triggerEndtime(isForce)
}

const _playInactionTimer = () => {
  if (!isOpen.value) return
  playInactionTimer()
}

defineExpose({
  open,
  close,
  playInactionTimer: _playInactionTimer,
})

</script>

<style lang="less">
.quick-expand-cart {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  width: 36/37.5rem;
  height: 36/37.5rem;
  font-size: 12px;
  background-color: rgba(#000, .75);
  border-radius: 46/37.5rem;
  transition: all var(--time) ease-out;

  &_open {
    .quick-expand-cart__core {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 20;
      }
    }
  }

  &__core {
    flex-shrink: 0;
    transition: all var(--time) ease-out;
    width: 36/37.5rem;
    height: 36/37.5rem;
  }

  &__wrap {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    border-radius: 36/37.5rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    overflow: hidden;
  }

  &__content {
    flex: 1;
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 12/37.5rem;
    margin-right: 8/37.5rem;
    height: 100%;
    color: #fff;
    width: 192/37.5rem;
  }

  &__right {
    flex-shrink: 0;
    max-width: 60%;
    height: 100%;
    border: 2/37.5rem solid transparent;
  }

  &__btn {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 14/37.5rem;
    height: 100%;
    color: #222;
    font-size: 12px;
    font-weight: 600;
    background-color: #fff;
    border-radius: 36/37.5rem;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__btn-tag {
    position: absolute;
    padding: 0 4/37.5rem;
    display: flex;
    align-items: center;
    top: 0;
    right: 0;
    max-width: 108/37.5rem;
    font-size: 10px;
    color: var(--btn-tag-color);
    background-color: var(--btn-tag-bg-color);
    border: 1px solid #fff;
    border-bottom-right-radius: 0;
    border-radius: 12px;
    transform: translate(-2px, -4px) scale(0.8);
    transform-origin: right;

    > img {
      margin-right: 2.5px;
      width: 15px;
      height: 15px;
    }

    > span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__promotion {
    display: flex;
    align-items: center;
  }

  &__promotion-icon {
    flex-shrink: 0;
    margin-right: 8/37.5rem;
    width: 30/37.5rem;
    height: 30/37.5rem;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__promotion-imgs {
    position: relative;
    flex-shrink: 0;
    margin-right: 8/37.5rem;
    width: 30/37.5rem;
    height: 30/37.5rem;

    &:has(.quick-expand-cart__promotion-img2) {
      margin-right: 14/37.5rem;
    }
  }

  &__promotion-img1,
  &__promotion-img2 {
    position: absolute;
    border-radius: 3/37.5rem;

    &:after {
      content: '';
      position: absolute;
      z-index: 6;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 1px solid rgba(#fff, 0.6);
      border-radius: 3/37.5rem;
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 3/37.5rem;
    }
  }

  &__promotion-img1 {
    z-index: 3;
    width: 30/37.5rem;
    height: 30/37.5rem;
  }

  &__promotion-img2 {
    z-index: 1;
    width: 26/37.5rem;
    height: 26/37.5rem;
    top: 2/37.5rem;
    right: -6/37.5rem;
    opacity: 0.5;
  }

  &__promotion-text {
    line-height: normal;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  &__save-text {
    line-height: normal;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
}

.quick-expand-cart__point-enter-active,
.quick-expand-cart__point-leave-active {
  position: absolute;
  transition: all 300ms;
}
.quick-expand-cart__point-enter-from {
  opacity: 0;
  transform: translateY(100%);
}
.quick-expand-cart__point-enter-to,
.quick-expand-cart__point-leave-from {
  opacity: 1;
  transform: translateY(0);
}
.quick-expand-cart__point-leave-to {
  opacity: 0;
  transform: translateY(-100%);
}
</style>
