<template>
  <!-- 最外面这一层为了处理气泡动画DOM包的 -->
  <div
    v-if="!IS_RW"
    class="j-cart-bag-container j-quick-cart-container"
  >
    <div
      :class="[
        'cart-bag-wrapper',
        searchFeedbackVisibility && 'cart-bag-wrapper__has-feedback',
      ]"
    >
      <QuickCartTip
        :html-text="bubbleTipText"
        :status="quickCartTipStatus"
        @click="onClickQuickCartTip"
      />
      <!-- 拉通态 -->
      <QuickExpandCart
        ref="quickExpandCartRef"
        :scene="scene"
        :is-custom-trigger="isCustomTriggerExpandCart"
        @click="onClickQuickExpandCart"
        @click-btn="onClickQuickExpandCartBtn"
        @change="onChangeQuickExpandCart"
      >
        <!-- 丢车动画用的DOM -->
        <div
          class="transition-container"
        >
          <div
            v-for="(image,index) in images"
            :key="index"
          >
            <transition
              v-if="isMounted"
              @before-enter="beforeDrop"
              @enter="dropping"
              @after-enter="afterDrop"
            >
              <div
                v-show="image.show"
                :class="['transition-container__image', searchFeedbackVisibility ? 'search-feedback': '']"
              >
                <div class="transition-inner transition-inner-hook"></div>
              </div>
            </transition>
          </div>
        </div>
        <!-- 利诱点推全的悬浮购物车 -->
        <div
          ref="quickCart"
          class="cart-bag cart-bag-new"
          @click.stop="handleQuickCart"
        >
          <i class="cart-bag__icon suiiconfont sui_icon_nav_cart_22px"></i>
          <span
            :class="[
              'quick-cart-num',
              'quick-cart-num__free',
              animateQuickCartNumCtn && beforeCartSumQuantity < 99 && 'quick-cart-num__animate',
              beforeCartSumQuantity >= 99 && 'quick-cart-num__max'
            ]"
          >
            <span class="quick-cart-num__roll">
              <span
                class="quick-cart-num__scroll"
                :class="[animateQuickCartNumScroll ? 'quick-cart-num__scroll-run': '']"
              >
                <span>{{ beforeCartSumQuantity < 99 ? beforeCartSumQuantity : ((beforeCartSumQuantity === postCartSumQuantity && beforeCartSumQuantity === 99) ? beforeCartSumQuantity : `99+`) }}</span>
                <span>{{ postCartSumQuantity <= 99 ? postCartSumQuantity : `99+` }}</span>
              </span>
            </span>
          </span>
          <!-- 0到1 加载圈SVG -->
          <QuickCartCircle
            :stroke="comStyleInfo?.circleStrokeColor"
            :animate-cart-bag-stroke="animateQuickCartStroke"
            :quick-expand-cart-is-open="quickExpandCartIsOpen"
          />
          <!-- 利诱信息, 购物车底部标签 -->
          <QuickCartTag
            v-show="showQuickCartTag"
            :label="label"
          />
        </div>
      </QuickExpandCart>
      <CartNewTips
        ref="CartNewTips"
        :app-style-control-style="appStyleControlStyle"
        scene="QuickCart"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, nextTick } from 'vue'
import gsap from 'gsap'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
import { daEventCenter } from '@/public/src/services/eventCenter/index.js'
import { markPoint } from '@/public/src/services/mark/index.js'

import QuickCartCircle from './components/QuickCartCircle.vue'
import QuickCartTag from './components/QuickCartTag.vue'
import QuickCartTip from './components/QuickCartTip.vue'
import QuickExpandCart from './components/QuickExpandCart/index.vue'
import CartNewTips from 'public/src/pages/product_app/footer/cart/popover/index.vue'
import MiniCart from 'public/src/pages/cart_v2/components/business/mini-cart/index.js'
import { useLureBubbleHistory } from 'public/src/pages/product_app/footer/cart/hooks/useLureBubbleHistory.js'
import { useLastLureBubble } from 'public/src/pages/product_app/footer/cart/hooks/useLastLureBubble.js'
import { isNormalType, jumpCart } from 'public/src/pages/product_app/footer/cart/utils.js'
import { web_cart_entry_click } from 'public/src/pages/cart_v2/utils/metricTagsConfig.js'
import { cartTagTip, registerCart, unregisterCart } from '@shein-aidc/bs-sdk-cart-tag-tip'
import { useDisabledLure } from './hooks/useDisabledLure.js'

const { langPath, IS_RW, GB_cssRight } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

daEventCenter.addSubscriber({ modulecode: '2-15-1' })

let tl = gsap.timeline({
  repeatRefresh: true,
})

const IMAGE_LEN = 10
const innerClsHook = `transition-inner-hook`

function createImages() {
  const images = []
  for (let i = 0; i < IMAGE_LEN; i++) {
    images.push({ show: false })
  }
  return images
}
const infoTable = {
  freeshipping: {
    circleStrokeColor: '#1B8157',
  },
  save: {
    circleStrokeColor: '#FA6338',
  },
  gift: {
    circleStrokeColor: '#FA6338',
  }
}

// 记录埋点相关参数用
let exposeParams = {}

export default defineComponent({
  name: 'QuickCart',
  components: {
    QuickCartCircle,
    QuickCartTag,
    QuickCartTip,
    QuickExpandCart,
    CartNewTips,
  },
  emits: ['click-cart', 'mounted'],
  props: {
    searchFeedbackVisibility: {
      type: Boolean,
      default: false
    },
    goodsId: {
      type: String,
      default: ''
    },
    disabledCartTagTipsShowtime: Boolean,
    // 标识当前使用悬浮购物车的地方，主要是解决半屏列表（实时反馈）悬浮购物车同时存在问题
    useFrom: {
      type: String,
      default: ''
    },
    //是否是不贵频道
    isReportCustomized: {
      type: Boolean,
      default: false,
    },
    // 上报普通非标车组件所在的页面的页面名称
    // @see: wiki 1588035771
    page_from: {
      type: String,
      default: '',
    },
    // 场景state
    // @see: wiki 1588035771
    state: {
      type: String,
      default: '',
    },
    // false 不接入普通非标车，悬浮购物车保持线上交互
    // true 悬浮购物车接入普通非标车，点击悬浮购物车（包括展示利益点气泡时）均唤起普通非标车
    // @see: wiki 1588035771
    isOpenMiniCart: {
      type: Boolean,
      default: false,
    },
    lureScene: {
      type: String,
      default: '',
    },
    // 场景值
    // 泛列表 page_list_hovercart
    // 商详 item_detail_hovercart
    // 支付成功页 paid_successfully_hovercart
    scene: {
      type: String,
      default: '',
    },
    // 是否由外围控制拉通态展开收起时机
    isCustomTriggerExpandCart: {
      type: Boolean,
      default: false,
    },
    // 是否使用拉通态购物车
    isExpandCart: {
      type: Boolean,
      default: false,
    },
    // 是否使用新sdk
    useNewSdk: {
      type: Boolean,
      default: false,
    },
    disabledFirstLure: {
      type: Boolean,
      default: false,
    },
    beforeRouterPush: { // router.push 前的回调
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const { setLureBubbleHistory } = useLureBubbleHistory()
    const { setLastLureBubble } = useLastLureBubble()
    const { disabledLure, setIsTriggerShowtime } = useDisabledLure(props.scene, props.disabledFirstLure)
    return {
      setLureBubbleHistory,
      setLastLureBubble,
      disabledLure,
      setIsTriggerShowtime,
    }
  },
  data() {
    return {
      isMounted: false,
      cartListener: null,
      langPath,
      images: createImages(),
      animateQuickCartStroke: false,
      animateQuickCartNumScroll: false,
      animateQuickCartNumCtn: false,
      animateCartFree: false,
      beforeCartSumQuantity: 1,
      postCartSumQuantity: 1,
      listcartinfoSwitch: false, // 利诱点信息abt值，参见MR-9157

      isDropFromTop: false, // 是否从顶部开始丢车
      quickExpandCartIsOpen: false,

      // 针对利诱点信息需求
      tl: null,
      callback: null,
      appStyleControlStyle: '',
      label: null,
      lastBubble: null,
      bubbleTipText: '', // 气泡多语言文案信息
      IS_RW,
      GB_cssRight,
    }
  },
  computed: {
    showQuickCartTag() {
      return !this.disabledLure && !this.quickExpandCartIsOpen
    },
    cartSumQuantity() {
      return this.$store.state.cartInfo.sum
    },
    isGoodsDetailScene() {
      return this.$route?.meta?.pageType === 'productDetail'
    },
    quickCartTipStatus() {
      return this.label?.type || ''
    },
    comStyleInfo() {
      if (!this.disabledLure && !this.quickExpandCartIsOpen && infoTable[this.quickCartTipStatus]) return infoTable[this.quickCartTipStatus]
      return {
        circleStrokeColor: '#231f20',
      }
    },
  },
  watch: {
    '$route'() {
      if (typeof window === 'undefined') return
      this.lastBubble = null
    }
  },
  async mounted() {
    this.registerCart()

    await this.fetchFreeInfo()
    this.isMounted = true
    window._gb_list_cart_ = this
    if(!this.useFrom) {
      appEventCenter.on('closeRecommendDrawerList', ()=> {
        this.registerCart()
        window._gb_list_cart_ = this
        // 解决，半弹层列表中购物车还在气泡动画时，关闭半弹层弹窗，外面列表的购物车显示异常（会存在动画过渡状态）
        this.endtime({ isForce: true })
        this.enterPageInitStatus()
      })
    }
    this.initExpose()

    this.$emit('mounted')

    // console.log(`%c 场景值: ${this.scene} `, 'color: #fff; background: red; font-size: 12px; font-weight: bold; padding: 4px 8px; border-radius: 4px;')
    // console.log(`%c 是否展开拉通态: ${this.isExpandCart} `, 'color: #fff; background: red; font-size: 12px; font-weight: bold; padding: 4px 8px; border-radius: 4px;')
    // console.log(`%c 是否外围控制展开收起时机: ${this.isCustomTriggerExpandCart} `, 'color: #fff; background: red; font-size: 12px; font-weight: bold; padding: 4px 8px; border-radius: 4px;')
  },
  async activated() {
    // 解决从购物车页面回来数量不同步问题。（在这个店铺名称没消失前就返回，数量就会对不上）
    this.isMounted = true
    window._gb_list_cart_ = this
    this.updateCartNum()
    // hack code 购物车列表操作完增，减 快速点击返回列表，购物车数量不同步问题
    setTimeout(()=> {
      this.updateCartNum()
    }, 800)
    if(!tl) {
      tl = gsap.timeline({
        repeatRefresh: true,
      })
    }
    this.registerCart()
    await this.enterPageInitStatus()
  },
  deactivated() {
    this.isMounted = false
    // 中断动画，垃圾回收, 千万别动
    this.endtime({ isForce: true })
    tl = null
    this.unregisterCart()
    // appEventCenter.off('closeRecommendDrawerList')
  },
  beforeDestory() {
    this.unregisterCart()
  },
  created() {
    this.dropImages = []
    // this.fetchFreeInfo()
  },
  methods: {
    registerCart() {
      if (!this.cartListener) {
        this.cartListener = {
          showtimeByBff: (dataByBff, config) => {
            this.setIsTriggerShowtime()
            this.appStyleControlStyle = dataByBff?.appStyleControl?.style
            // 如果悬浮购物车数量不是最新的，则兜底再触发一次数量更新动画
            if (this.diffCartNum()) {
              console.warn('the number of quick cart is abnormal, triggering a bottom-up update!')
              this.cartNumAnimate()
            }
            this.showtime(dataByBff, config)
          },
          endtime: (config) => {
            this.endtime(config)
          }
        }
      }
      registerCart('quick-cart', this.cartListener, { only: true })
    },
    unregisterCart() {
      unregisterCart('quick-cart', this.cartListener, { only: true })
      this.cartListener = null
    },
    // 初始进去场景获取免邮信息相关, 参见TR-8205
    async fetchFreeInfo() {
      // 获取ABT配置
      let { listcartinfoSwitch } = await getUserAbtData() // 可以不需要在场景里获取listcartinfoSwitch进行逻辑判断的(public/src/pages/product_app/util/cartTagTips.js中有相关处理)，为了好理解所以没去掉listcartinfoSwitch的相关逻辑
      this.listcartinfoSwitch = listcartinfoSwitch?.p?.list_entrance_info_switch === 'on' // 利诱点信息abt(public/src/services/abt/config/common.js 中进行的配置)
      // 利诱点逻辑，初始化进入列表，从缓存获取当前利诱点数据
      if(this.listcartinfoSwitch) {
        if(typeof window !== 'undefined') {
          await this.enterPageInitStatus()
          return
        }
      }
    },
    setDefaultCartNum() {
      this.animateQuickCartStroke = false
      this.animateQuickCartNumScroll = false
      this.animateQuickCartNumCtn = false
      this.animateCartFree = false
      this.beforeCartSumQuantity = 1
      this.postCartSumQuantity = 1
    },
    updateCartNum() {
      if (this.cartSumQuantity > 0) {
        this.beforeCartSumQuantity = this.cartSumQuantity
        this.postCartSumQuantity = this.cartSumQuantity
        this.animateQuickCartStroke = true
        this.animateQuickCartNumCtn = true
        this.animateCartFree = true
      }
      if (this.cartSumQuantity === 0) {
        this.setDefaultCartNum()
      }
      this.isMounted = true
    },

    async initExpose() {
      // 等待 cartInfo.sum 更新，无法在 nextTick 中获取，不可重复触发
      setTimeout(() => {
        this.updateCartNum()
        this.expose(this.cartSumQuantity)
      }, 2000)
    },
    async expose(cartSumValue) {
      if(this.isGoodsDetailScene) return
      exposeParams = await window?._gb_cart_tag_tips_?.getExposeData()
      // 购物车悬浮窗icon曝光
      daEventCenter.triggerNotice({
        daId: '1-5-1-29',
        extraData: {
          component: 1,
          bag_goods_count: cartSumValue,
          goodsId: this.goodsId,
          ...exposeParams,
        },
      })
    },
    // 商详侧用
    exposeDetailCarIcon() {
      daEventCenter.triggerNotice({
        daId: '1-6-4-76',
        extraData: {
          component: 1,
          bag_goods_count: this.cartSumQuantity,
          goodsId: this.goodsId,
          ...exposeParams,
        },
      })
    },
    triggerShowTime() {
      // 组件内自行触发，因为加车后一定会触发这个方法。（为了兼容旧的抛车动画）
      if (!this.disabledCartTagTipsShowtime) {
        if (this.useNewSdk) {
          cartTagTip.showtime({ lureScene: this.lureScene })
        } else {
          window?._gb_cart_tag_tips_?.showtime({ lureScene: this.lureScene })
        }
      }
    },
    diffCartNum() {
      return this.postCartSumQuantity !== this.cartSumQuantity
    },
    cartNumAnimate() {
      const hasShowedNumCtn = this.animateQuickCartNumCtn
      this.beforeCartSumQuantity = hasShowedNumCtn ? this.postCartSumQuantity : this.cartSumQuantity
      this.postCartSumQuantity = this.cartSumQuantity
      // 处理最多展示99+逻辑，此时数字不再需要过渡
      if(this.cartSumQuantity > 99 && this.postCartSumQuantity >= 99) {
        this.animateQuickCartNumCtn = false
      }else {
        this.animateQuickCartNumCtn = true
      }
      this.animateQuickCartStroke = true
      this.animateCartFree = true

      if (hasShowedNumCtn && this.beforeCartSumQuantity < 99) {
        this.animateQuickCartNumScroll = false
        setTimeout(() => {
          this.animateQuickCartNumScroll = true
        }, 550)
      } else {
        this.animateQuickCartNumScroll = false
      }
    },
    start({ target, isDropFromTop = false } = {}) {
      if (target) {
        this.isDropFromTop = isDropFromTop
        this.drop(target)
        // drop 链路已调用
        // this.cartNumAnimate()
        // this.triggerShowTime()
      } else {
        this.cartNumAnimate()
        this.triggerShowTime()
      }
    },
    drop(el) {
      for (let i = 0; i < this.images.length; i++) {
        const image = this.images[i]
        if (!image.show) {
          image.show = true
          image.el = el
          this.dropImages.push(image)
          return
        }
      }
    },
    beforeDrop(el) {
      const image = this.dropImages[this.dropImages.length - 1]
      const rect = (image?.el?.getBoundingClientRect && image.el.getBoundingClientRect()) ?? { left: 0, width: 0, top: 0, height: 0 } // 为了解决反馈中的购物加车和外面列表同步问题导致的报错
      const quickCartRect = this.$refs.quickCart?.getBoundingClientRect()
      const quickCartRectBottom = window.innerHeight - quickCartRect.top

      const rectTop = this.isDropFromTop ? 0 : rect.top
      const x = rect.left + rect.width / 2 - quickCartRect.left - rect.width / 2
      const y = -(window.innerHeight - quickCartRectBottom - rectTop - rect.height / 2) - rect.height / 2
      el.style.display = ''
      el.style.transform = el.style.webkitTransform = `translate3d(0,${y}px,0)`

      const inner = el.getElementsByClassName(innerClsHook)[0]
      const imageElementWrapper = document.createElement('div')
      const imageElement = image.el?.cloneNode && image.el?.cloneNode(true)?.querySelector('img')
      if (imageElement) imageElement.style = 'width:100%'
      imageElementWrapper.appendChild(imageElement || document.createElement('div'))

      inner.innerHTML = imageElementWrapper.innerHTML 
      inner.style.transform = inner.style.webkitTransform = `translate3d(${x}px,0,0)`
      inner.style.width = `${rect.width}px`
      inner.style.height = `${rect.height}px`
      inner.style.borderRadius = '0%'
    },
    dropping(el, done) {
      this._reflow = document.body.offsetHeight
      el.style.transform = el.style.webkitTransform = `translate3d(0,0,0)`
      const inner = el.getElementsByClassName(innerClsHook)[0]
      inner.style.transform = inner.style.webkitTransform = `translate3d(0,0,0)`
      inner.style.width = ''
      inner.style.height = ''
      inner.style.borderRadius = ''
      el.addEventListener('transitionend', done)
      // done()
    },
    afterDrop(el) {
      const image = this.dropImages.shift()
      if (image) {
        image.show = false
        el.style.display = 'none'
      }

      this.cartNumAnimate()

      this.triggerShowTime()
    },
    async handleQuickCart() {
      web_cart_entry_click('1')
      exposeParams = await window?._gb_cart_tag_tips_?.getExposeData()
      markPoint('toNextPageClick', 'public')
      this.$emit('click-cart')
      if (!this.isReportCustomized) {
        daEventCenter.triggerNotice({
          daId: this.isGoodsDetailScene ? '1-6-4-77' : '1-5-1-28',
          extraData: {
            component: 1,
            goodsId: this.goodsId,
            bag_goods_count: this.cartSumQuantity,
            ...exposeParams,
          },
        })
      }
      this.openMiniCart()
    },
    async openMiniCart({ scrollToCartItemId, lurePoint, hasPlayInactionTimer } = {}) {
      if (this.isOpenMiniCart) {
        MiniCart.open({
          scrollToCartItemId,
          lurePoint,
          analysisData: {
            state: this.state,
            page_from: this.page_from,
          },
          callbacks: {
            onClosed: () => {
              cartTagTip.showtime({
                lureScene: this.lureScene,
                noTips: true,
              })
              if (hasPlayInactionTimer) this.$refs.quickExpandCartRef.playInactionTimer()
            },
          },
        })
      } else {
        await this.beforeRouterPush?.()
        markPoint('toNextPageClick', 'public')
        this.$router.push(`${this.langPath}/cart`)
      }
    },
    async onClickQuickCartTip() {
      web_cart_entry_click('1')
      const extraData = await window?._gb_cart_tag_tips_?.getExposeData()

      daEventCenter.triggerNotice({
        daId: '2-15-3',
        extraData: extraData,
      })

      this.openMiniCart()
    },
    // 进页面执行的获取缓存，置顶标签逻辑
    async enterPageInitStatus() {
      try {
        const lure = await window?._gb_cart_tag_tips_?.getLure()
        this.label = lure?.showLabel
      } catch(e) {
        this.label = null
      }
    },
    getIsNewPopover(appStyleControl) {
      // 常驻气泡
      if (isNormalType(appStyleControl.bubble.type)) {
        return appStyleControl.style === '1' && !!appStyleControl.bubble.lurePointItem
      }
      return true
    },
    async showtime(dataByBff, config = {}) {
      if (this.isExpandCart) {
        this.$refs.quickExpandCartRef.open(dataByBff, config)
        // 更新常驻标签
        this.label = dataByBff?.appStyleControl?.showLabel
        return
      }
      this.endtime()
      this.callback = config.callback

      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async r => {
        const callback = () => {
          this.endtime()
          r()
        }
        // console.log(`%c 开始展示标签/气泡 ${new Date()}`, 'color: #fff; background: red; font-size: 12px; font-weight: bold; padding: 4px 8px; border-radius: 4px;')
        const { appStyleControl } = dataByBff
        // 没有数据，不展示标签
        if (!appStyleControl) {
          // console.log(`%c 失败: 无标签/气泡数据 ${new Date()}`, 'color: #fff; background: red; font-size: 12px; font-weight: bold; padding: 4px 8px; border-radius: 4px;')
          this.label = null
          return callback()
        }
        // 不展示利诱气泡，直接展示最终的标签
        if (config.noTip) {
          this.label = appStyleControl.showLabel
          return callback()
        }

        if (appStyleControl.showLabel || appStyleControl.newLabel) {
          // console.log(`%c 成功: 展示标签 ${new Date()}`, 'color: #fff; background: red; font-size: 12px; font-weight: bold; padding: 4px 8px; border-radius: 4px;')
        } else {
          // console.log(`%c 失败: 无标签 ${new Date()}`, 'color: #fff; background: red; font-size: 12px; font-weight: bold; padding: 4px 8px; border-radius: 4px;')
        }

        if (appStyleControl.bubble) {
          // console.log(`%c 成功: 展示气泡 ${new Date()}`, 'color: #fff; background: red; font-size: 12px; font-weight: bold; padding: 4px 8px; border-radius: 4px;')
        } else {
          // console.log(`%c 失败: 无气泡 ${new Date()}`, 'color: #fff; background: red; font-size: 12px; font-weight: bold; padding: 4px 8px; border-radius: 4px;')
        }

        let isNewPopover = false
        let labelDone = false
        let hasBubble = false
        let position // newLabel 标签停留时间
        if (appStyleControl.bubble) {
          hasBubble = true
          this.lastBubble = appStyleControl.bubble
          isNewPopover = this.getIsNewPopover(appStyleControl)
          this.setLureBubbleHistory('cartPage', appStyleControl.bubble.lurePointItem?.uniqueType)
          if (isNewPopover) {
            position = '+=4.5'
            // 打开气泡利诱
            this.$refs.CartNewTips?.open(appStyleControl.bubble, {
              callback: () => {
                if (labelDone) callback()
              },
            })
          } else {
            // 由 gsap 接管气泡动画
          }
        }

        if (isNewPopover) { // 新气泡 标签动画
          // 没有可展示的[常驻]标签 或者 没有可翻转的[常驻]标签
          if (!isNormalType(appStyleControl.newLabel.type) || !appStyleControl.showLabel || !appStyleControl.newLabel || appStyleControl.showLabel.type === appStyleControl.newLabel.type) {
            this.label = appStyleControl.showLabel
            if (hasBubble) {
              labelDone = true
              return // 存在气泡时，由气泡执行 callback()
            }
            return callback()
          }

          if (!this.label) this.label = appStyleControl.showLabel
          await nextTick()
          tl = gsap.timeline()

          // 如果 newLabel 是常驻标签，则执行翻转动画
          if (isNormalType(appStyleControl.newLabel.type)) {
            tl.to('.quick-cart-tag', { // 翻转标签
              duration: 0.3,
              rotationY: 90,
              opacity: 0,
              repeat: 1,
              yoyo: true,
              onRepeat: () => {
                this.label = appStyleControl.newLabel
              },
            })

            tl.to('.quick-cart-tag', { // 气泡消失后还原标签
              duration: 0.3,
              opacity: 0,
              repeat: 1,
              yoyo: true,
              onRepeat: () => {
                this.label = appStyleControl.showLabel
              },
              onComplete: () => {
                callback()
              },
            }, position)
          } else {
            // 临时标签无需处理
          }
        } else { // 旧气泡 标签+气泡动画
          const bubble = appStyleControl.bubble
          if (!bubble) {
            // 无气泡
            this.label = appStyleControl.showLabel
            return callback()
          }
          this.bubbleTipText = bubble.htmlText || `${bubble.text}${bubble.desc ? '<br/>' + bubble.desc : ''}`

          tl = gsap.timeline()

          // 没有可展示的标签 或者 没有可翻转的标签
          if (!appStyleControl.showLabel || !appStyleControl.newLabel || appStyleControl.showLabel.type === appStyleControl.newLabel.type) {
            tl.to('.quick-cart-tag', { // 标签消失
              opacity: 0,
              duration: 0.3,
              onComplete: () => {
                this.label = appStyleControl.showLabel
              }
            })
            tl.to('.quick-cart-tip', { // 气泡显示+隐藏
              width: 'auto',
              visibility: 'visible',
              duration: 0.3,
              repeat: 1,
              yoyo: true,
              repeatDelay: 3,
              onStart: () => {
                window?._gb_cart_tag_tips_?.getExposeData().then(extraData => {
                  daEventCenter.triggerNotice({
                    daId: '2-15-2',
                    extraData: extraData,
                  })
                })
              },
            })
            tl.to('.quick-cart-tag', { // 标签显示
              opacity: 1,
              duration: 0.3,
              onComplete: () => {
                callback()
              },
            })
          } else {
            tl.to('.quick-cart-tag', { // 标签消失
              opacity: 0,
              duration: 0.3,
              onComplete: () => {
                this.label = appStyleControl.newLabel
              },
            })
            tl.to('.quick-cart-tip', { // 气泡显示+隐藏
              width: 'auto',
              visibility: 'visible',
              duration: 0.3,
              repeat: 1,
              yoyo: true,
              repeatDelay: 3,
              onStart: () => {
                window?._gb_cart_tag_tips_?.getExposeData().then(extraData => {
                  daEventCenter.triggerNotice({
                    daId: '2-15-2',
                    extraData: extraData,
                  })
                })
              },
            })
            tl.to('.quick-cart-tag', { // 标签显示
              opacity: 1,
              duration: 0.3,
            })
            tl.to('.quick-cart-tag', { // 翻转标签
              duration: 0.3,
              rotationY: 90,
              opacity: 0,
              repeat: 1,
              yoyo: true,
              onRepeat: () => {
                this.label = appStyleControl.showLabel
              },
              onComplete: () => {
                callback()
              },
            }, '+=1')
          }
        }
      })
    },
    // 提供给公共部分的逻辑，停止动画用
    endtime({ isForce } = {}) {
      if (this.isExpandCart) {
        this.$refs.quickExpandCartRef.close(isForce)
        return
      }
      tl?.seek(0).kill()
      tl = null
      this.callback?.()
      this.callback = null

      this.$refs.CartNewTips?.clear?.()
    },
    onClickQuickExpandCart({ currentShowPoint } = {}) {
      if (this.isOpenMiniCart) {
        this.openMiniCart({
          scrollToCartItemId: currentShowPoint?.lurePointItem?.cartIds?.[0],
          lurePoint: currentShowPoint,
          hasPlayInactionTimer: true,
        })
        return
      }
      this.$refs.quickExpandCartRef.close(true)
      jumpCart(currentShowPoint, this.appStyleControlStyle, false, this.beforeRouterPush)
    },
    onClickQuickExpandCartBtn({ currentShowPoint } = {}) {
      this.$refs.quickExpandCartRef.close(true)
      jumpCart(currentShowPoint, this.appStyleControlStyle, false, this.beforeRouterPush)
    },
    onChangeQuickExpandCart(isOpen) {
      this.quickExpandCartIsOpen = isOpen
    },
  },
})
</script>

<style lang="less">
.quick-cart-num {
  position: absolute;
  top: -0.12rem;
  right: -8/37.5rem;
  display: flex;
  min-width: 20px;
  height: 16px;
  font-size: 12px;
  border-radius: 46px;
  overflow: hidden;
  justify-content: center;
  flex-wrap: nowrap;
  background: #fe3b30;
  color: #fff;
  opacity: 0;
  z-index: @zindex-attrmenusize;

  &__free {
    border: 1px solid #fff;
    box-sizing: content-box;
  }
  &__animate {
    animation: .2s cubic-bezier(0.4, 0.0, 1, 1) .3s forwards cart-num-fadeup;
  }
  &__max {
    opacity: 1;
  }
  &__roll {
    height: 16px;
    display: inline-block;
    transform-origin: top center;
    width: 100%;
    text-align: center;
  }
  &__roll {
    transform: scale(0.9);
  }
  &__scroll {
    display: inline-block;
  }
  &__scroll span {
    display: block;
    text-align: center;
  }
  &__scroll-run {
    animation: .3s cubic-bezier(0.4, 0.0, 1, 1) forwards cart-num-roll;
  }
}

@keyframes cart-num-roll {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-50%);
  }
}
@keyframes cart-num-fadeup {
  from {
    opacity: 0;
    transform: translate3d(0, 5px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.show-scroll-top .cart-bag-wrapper {
  transform: translateY(var(--quick-cart-offset-y, -1.436rem));
}
.show-scroll-top .product-middle__cart-bag .cart-bag-wrapper {
  transform: translateY(-0.636rem);
}
.show-scroll-top .cart-bag-wrapper__has-feedback {
  transform: translateY(var(--feedback-quick-cart-offset-y, -0.9067rem));
}

.cart-bag-wrapper {
  position: fixed;
  .right(0.51rem);
  bottom: var(--quick-cart-wrapper-bottom, 2.0267rem);
  width: 36/37.5rem;
  height: 36/37.5rem;
  border-radius: 100%;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12);
  // z-index: @zindex-returnTop;
  z-index: var(--quick-cart-z-index, 99);
  transform: translateY(0);
  transition: transform .3s cubic-bezier(0.4, 0.0, 0.2, 1);
  margin-bottom: calc(var(--low-stock-banner-bottom-gap, 0px) + env(safe-area-inset-bottom));
}
.cart-bag-wrapper__has-feedback {
  bottom: var(--feedback-quick-cart-bottom, 3.2533rem);
}

.cart-bag {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: 100%;
  height: 100%;
  border-radius: 100%;

  &__icon {
    display: block;
    color: #000000;
    z-index: @zindex-hack;
  }
}

.transition-container {
  &__image {
    position: absolute;
    top: 0;
    left: 0;
    z-index: @zindex-attrmenusize;
    transition: all 0.4s cubic-bezier(0.4, 0.0, 1, 1);
    & > .transition-inner {
      overflow: hidden;
      width: 0.96rem;
      height: 0.96rem;
      border-radius: 100%;
      transition: all 0.4s cubic-bezier(0.4, 0.0, 1, 1);
    }
  }
}
</style>
