import { ref, computed, onMounted } from 'vue'

export const useDisabledLure = (scene, disabledFirstLure) => {
  const cacheKey = `quick_cart_is_trigger_showtime_${scene}`
  const _disabledFirstLure = ref(disabledFirstLure)
  const disabledLure = computed(() => _disabledFirstLure.value)

  const getIsTriggerShowtime = () => {
    return sessionStorage.getItem(cacheKey)
  }

  const setIsTriggerShowtime = (val = 1) => {
    sessionStorage.setItem(cacheKey, val)
    checkDisabledFirstLure()
  }

  const checkDisabledFirstLure = () => {
    if (_disabledFirstLure.value) {
      const res = getIsTriggerShowtime()
      if (res) _disabledFirstLure.value = false
    }
  }

  onMounted(() => {
    checkDisabledFirstLure()
  })

  return {
    disabledLure,
    setIsTriggerShowtime,
  }
}
